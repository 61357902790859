.loading-preloader {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: var(--#{$prefix}white);
	z-index: 99999;
	.spinner-border {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
}