.tabs-main-mobile-menu {
	max-width: 300px;
	.btn-close {
		position: fixed;
		top: 0;
		left: 300px;
		opacity: 1;
		z-index: 1;
		background: var(--#{$prefix}white);
		color: var(--#{$prefix}dark);
		padding: 0;
		line-height: 1;
		font-size: 26px;
		width: 35px;
		height: 35px;
	}
	.modal-body {
		padding: 0;
	}
	.header-search {
		border-bottom: 1px solid var(--#{$prefix}gray-300);
		padding: 12px;
	}
	.nav-tabs {
		border: none;
		background: var(--#{$prefix}gray-200);
		.nav-item {
			flex: 1;
			.nav-link {
				width: 100%;
				border: none;
				margin: 0;
				font-weight: 600;
				color: var(--#{$prefix}dark);				
			    padding: 10px;
			    text-transform: uppercase;
			    font-size: 14px;

			}
		}
	}
	.tab-content {
		.tab-pane {
			padding: 15px;
		}
	}
	.mob-menu-link-top {
		margin-bottom: 15px;
		padding-bottom: 25px;
		border-bottom: 1px solid var(--#{$prefix}gray-300);
		ul {
			margin: 0;
			li {
				+ li {
					padding-top: 10px;
				}
			}
			a {
				color: var(--#{$prefix}dark);
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
	.mob-menu-link-main {
		li {
			position: relative;
			a {
				color: var(--#{$prefix}dark);
				
				display: inline-flex;
				font-weight: 400;
    			font-size: 14px;
			}
			.mob-menu {
			    position: absolute;
			    top: 8px;
			    right: 0;
			    &.open {
			    	i {
			    		&:before {
			    			content: "\f286";
			    		}
			    	}
			    }
			}
		}
		.mob-menu-level-1 {
			> li {
				+ li {
					border-top: 1px solid var(--#{$prefix}gray-300);
				}
				> a {
					padding: 8px 0;
				}
				&.open-menu-parent {
					> a {
						color: var(--#{$prefix}primary);
					}
				}
			}
		}
		.mob-menu-level-2  {
		    position: relative;
		    margin: 0;
		    padding: 0 0 10px 15px;
		    width: 100%;
		    a {
				padding: 5px 0;
			}
		}
	}
}