.header-option-5 {
	transition: ease top 0.35s;
	.header-middle {
		padding-top: 25px;
		padding-bottom: 25px;
		@include media-breakpoint-down(md) {
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	.h-search {
		position: relative;
		max-width: 300px;
		@include media-breakpoint-down(md) {
		    max-width: 100%;
			margin: 15px 0;
		}
		.form-control {
		    padding: 0.45rem 0.65rem;
		    font-size: 0.83rem;
		    background: none;
		    border: 1px solid transparent;
		    &:focus {
		    	border: 1px solid rgba(var(--#{$prefix}dark-rgb), .07);
		    }
		    @include media-breakpoint-down(md) {
		    	border: 1px solid rgba(var(--#{$prefix}dark-rgb), .07);
		    }
		}
		.btn {
		    position: absolute;
		    right: 0;
		    top: 0;
		    bottom: 0;
		    margin: auto;
		    width: 35px;
		    height: 35px;
		    padding: 0;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		}
	}
	.header-right {
    	.nav-item {
    		+ .nav-item {
    			margin-left: 10px;
    		}
    	}
        .nav-link {
            padding: 0;
		    width: 35px;
		    height: 35px;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		    color: var(--#{$prefix}dark);
		    line-height: 1;
            i {
                font-size: 1.6rem;
            }
        }
    }
	.navbar-nav {
		> .nav-item {
			> .nav-link {
				font-weight: $px-header-font-weight;
				text-transform: $px-header-link-transform;
				font-size: $px-header-link-font-size;
			}
			@include mob_menu_icon();
		}
	}
    .navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            // stylelint-disable-next-line scss/selector-no-union-class-name
            &#{$infix} {
                @include media-breakpoint-up($next) {
                	border-top: 1px solid rgba(var(--#{$prefix}dark-rgb), .1);
                	box-shadow: $box-shadow-sm;
                    .navbar-nav {
                        >.nav-item {
                            >.nav-link {
                                line-height: 50px;
                                padding: 0 10px;
                                position: relative;
                                font-size: 14px;
                                text-transform: uppercase;
                                &:before {
                                	content: "";
                                	position: absolute;
                                	left: auto;
	                                right: 0;
                                	bottom: 0;
                                	height: 2px;
                                	width: 0px;
                                	background: var(--#{$prefix}primary);
                                	transition: ease width 0.35s;
                                }
                            }
                            &:hover {
                            	> .nav-link {
                            		&:before {
                            			left: 0;
                                		right: auto;
	                                	width: 100%;
	                                }
                            	}
                            }
                        }
                    }
                }
            }
        }
    }
}