.bg-cover {
	background-size: cover;
}
.bg-right-center {
    background-position: center right;
}
.bg-50 {
    background-position: 50%;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-center {
	background-position: center;
}

.bg-black {
	background: var(--#{$prefix}black);
}
