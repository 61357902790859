.header-option-1 {
    .navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);
            // stylelint-disable-next-line scss/selector-no-union-class-name
            &#{$infix} {
            	@include media-breakpoint-down($next) {
            		padding-top: 10px;
            		padding-bottom: 10px;
            	}
                @include media-breakpoint-up($next) {
                    .navbar-nav {
                        >.nav-item {
                            >.nav-link {
                                line-height: $px-header-link-height-60;
                            }
                        }
                    }
                }
            }
        }
    }
    .header-right {
		.nav-link {
			padding: 0.3rem 0.6rem;
			i {
		    	font-size: 1.3rem;
			}
		}
	}
	.navbar-toggler {
		border: none;
	    outline: none;
	    box-shadow: none;
	    padding: 0;
	    .navbar-toggler-icon {
	    	width: 1.8em;
    		height: 1.8em;
	    }
	}
	.navbar-nav {
		> .nav-item {
			> .nav-link {
				font-weight: $px-header-font-weight;
				text-transform: $px-header-link-transform;
				font-size: $px-header-link-font-size;
			}
			@include mob_menu_icon();
		}
	}
	.header-social {
		.h-social-link {
		    padding: 0 4px;
			font-size: 14px;
			vertical-align: middle;
		}
	}
}