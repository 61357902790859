.header-option-2 {
    .header-top {
        border-bottom: 1px solid rgba(var(--#{$prefix}white-rgb), .2);
    }
    .navbar {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-expand {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            // stylelint-disable-next-line scss/selector-no-union-class-name
            &#{$infix} {
                @include media-breakpoint-up($next) {
                    .navbar-nav {
                        >.nav-item {
                            >.nav-link {
                                line-height: $px-header-link-height-60;
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .header-right {
    	.nav-item {
    		+ .nav-item {
    			margin-left: 15px;
    		}
    	}
        .nav-link {
            padding: 0;
		    width: 35px;
		    height: 35px;
		    display: inline-flex;
		    align-items: center;
		    justify-content: center;
		    border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.4);
		    border-radius: 50%;
		    line-height: 1;
            i {
                font-size: 19px;
            }
        }
    }

    .navbar-toggler {
        padding: 0;
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(var(--#{$prefix}white-rgb), 0.4);
        border-radius: 50%;
        line-height: 1;
        margin-left: 10px;
        .navbar-toggler-icon {
            width: 18px;
            height: 18px;
        }
    }

    .navbar-nav {
        >.nav-item {
            >.nav-link {
                font-weight: $px-header-font-weight;
                text-transform: $px-header-link-transform;
                font-size: $px-header-link-font-size;
            }

            @include mob_menu_icon();
        }
    }

    .header-social {
        .h-social-link {
            padding: 0 4px;
            font-size: 14px;
            vertical-align: middle;
        }
    }

    &.fixed-header {
    	.header-right {
    		.nav-link {
    			border: 1px solid rgba(var(--#{$prefix}dark-rgb), 0.1);
    		}
    	}
        .navbar-toggler {
            border: 1px solid rgba(var(--#{$prefix}dark-rgb), 0.4);
        }
    }
}