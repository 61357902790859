.sm-title-01 {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-left: 40px;
	padding-right: 40px;
	&:before,
	&:after {
		content:"";
		position: absolute;
		bottom: 0;
		top: 0;
		margin: auto;
		height: 2px;
		width: 25px;
		background: var(--#{$prefix}primary);
	}
	&:before {
		left: 0;
	}
	&:after {
		right: 0;
	}
}

.sm-title-02 {
	position: relative;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--#{$prefix}gray-300);
	&:after {
		content:"";
		position: absolute;
		bottom: -2px;
		left: 0;
		height: 3px;
		width: 30px;
		background: var(--#{$prefix}primary);
	}
}

.sm-title-03 {
	font-size: 12px;
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: 1px;
}

.sm-title-04 {
	text-transform: uppercase;
	margin: 0px 0 15px;
	position: relative;
	padding: 0 0 8px;
	font-weight: 500;
	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 25px;
		height: 2px;
		background: var(--#{$prefix}primary);
	}
}

.sm-title-05 {
	font-size: 12px;
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: 3px;
    font-weight: 400;
}