article {
    h4,
    h5 {
        margin-bottom: 1.5rem;

        &:not(:first-child) {
            margin-top: 2rem;
        }
    }

    p {
        line-height: 1.9;
    }
	figure {
	    margin: 3rem 0;
	    + h4,
		+ h5 {
		    margin-top: 0 !important;
		}
	}
	blockquote {
		border-left: 5px solid var(--#{$prefix}gray-300);
		padding-left: 2rem;
		margin-top: 2rem;
		margin-bottom: 3rem;
		display: inline-block;
		vertical-align: top;
		width: 100%;
		+ h4,
		+ h5 {
		    margin-top: 0 !important;
		}
	}
}

